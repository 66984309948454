/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #ff4d4f;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #ff4d4f;
}
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: -4px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 10px;
}
.ant-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden,
.ant-dropdown-menu-submenu-hidden {
  display: none;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-top,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 15.3137085px;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottom,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 15.3137085px;
}
.ant-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-dropdown-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  -webkit-clip-path: inset(33% 33%);
          clip-path: inset(33% 33%);
  -webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
          clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-dropdown-placement-top > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 10px;
  box-shadow: 3px 3px 7px -3px rgba(0, 0, 0, 0.1);
  transform: rotate(45deg);
}
.ant-dropdown-placement-top > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-placement-bottom > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 9.41421356px;
  box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.1);
  transform: rotate(-135deg) translateY(-0.5px);
}
.ant-dropdown-placement-bottom > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(-135deg) translateY(-0.5px);
}
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}
.ant-dropdown-menu-item {
  position: relative;
  display: flex;
  align-items: center;
}
.ant-dropdown-menu-item-icon {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}
.ant-dropdown-menu-title-content {
  flex: auto;
}
.ant-dropdown-menu-title-content > a {
  color: inherit;
  transition: all 0.3s;
}
.ant-dropdown-menu-title-content > a:hover {
  color: inherit;
}
.ant-dropdown-menu-title-content > a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color: #9e3226;
  background-color: #ded4d1;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: #f5f5f5;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled:hover,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled a,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled a,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled a,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled a {
  pointer-events: none;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  font-style: normal;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 24px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #9e3226;
}
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading,
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn {
  cursor: default;
  pointer-events: none;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn::before {
  display: block;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #9e3226;
}
.ant-dropdown-rtl {
  direction: rtl;
}
.ant-dropdown-rtl.ant-dropdown::before {
  right: -7px;
  left: 0;
}
.ant-dropdown-menu.ant-dropdown-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item-group-title,
.ant-dropdown-menu-submenu-rtl .ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
  transform-origin: 100% 0;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  right: auto;
  left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  transform: scaleX(-1);
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-menu-item-danger.ant-menu-item {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-item-danger.ant-menu-item-active {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item:active {
  background: #fff1f0;
}
.ant-menu-item-danger.ant-menu-item-selected {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item-selected > a,
.ant-menu-item-danger.ant-menu-item-selected > a:hover {
  color: #ff4d4f;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #fff1f0;
}
.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: #ff4d4f;
}
.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: #ff4d4f;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #fff;
  background-color: #ff4d4f;
}
.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #d1beb8;
}
.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-menu-overflow {
  display: flex;
}
.ant-menu-overflow-item {
  flex: none;
}
.ant-menu-hidden,
.ant-menu-submenu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  height: 1.5715;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: all 0.3s;
}
.ant-menu-horizontal .ant-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
  color: #9e3226;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #ded4d1;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-title-content {
  transition: color 0.3s;
}
.ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-item a:hover {
  color: #9e3226;
}
.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.ant-menu-item > .ant-badge a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-item > .ant-badge a:hover {
  color: #9e3226;
}
.ant-menu-item-divider {
  overflow: hidden;
  line-height: 0;
  border-color: #f0f0f0;
  border-style: solid;
  border-width: 1px 0 0;
}
.ant-menu-item-divider-dashed {
  border-style: dashed;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #9e3226;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #9e3226;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ded4d1;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}
.ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  transition: border-color 0.3s, background 0.3s;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}
.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin-left: 10px;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}
.ant-menu-item .ant-menu-item-icon.svg,
.ant-menu-submenu-title .ant-menu-item-icon.svg {
  vertical-align: -0.125em;
}
.ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-submenu-title.ant-menu-item-only-child > .anticon,
.ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
  margin-right: 0;
}
.ant-menu-item:not(.ant-menu-item-disabled):focus-visible,
.ant-menu-submenu-title:not(.ant-menu-item-disabled):focus-visible {
  box-shadow: 0 0 0 2px #d1beb8;
}
.ant-menu > .ant-menu-item-divider {
  margin: 1px 0;
  padding: 0;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  border-radius: 2px;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' ';
}
.ant-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 2px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: rgba(0, 0, 0, 0.85);
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #9e3226;
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2.5px);
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2.5px);
}
.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2.5px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2.5px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #9e3226;
}
.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #9e3226;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #9e3226;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  position: absolute;
  right: 20px;
  bottom: 0;
  left: 20px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}
.ant-menu-horizontal > .ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #9e3226;
}
.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #9e3226;
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #9e3226;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  display: flex;
  align-items: center;
  transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > *,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
  flex: none;
}
.ant-menu.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed .anticon {
  display: inline-block;
}
.ant-menu.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  background: #fafafa;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  cursor: not-allowed;
}
.ant-menu-item-disabled::after,
.ant-menu-submenu-disabled::after {
  border-color: transparent !important;
}
.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-layout-header .ant-menu {
  line-height: inherit;
}
.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a:hover {
  color: #fff;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #9e3226;
}
.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #781d17;
}
.ant-menu-dark .ant-menu-item:focus-visible,
.ant-menu-dark .ant-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 2px #781d17;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #001529;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #9e3226;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #fff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #9e3226;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #9e3226;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled > span > a,
.ant-menu-dark .ant-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu.ant-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none;
}
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: top right;
}
.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 10px;
}
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
  margin-left: 0;
}
.ant-menu-submenu-rtl.ant-menu-submenu-popup {
  transform-origin: 100% 0;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateY(-2px);
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateY(2px);
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}
.ant-menu-sub.ant-menu-inline {
  border: 0;
}
.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-space {
  display: inline-flex;
}
.ant-space-vertical {
  flex-direction: column;
}
.ant-space-align-center {
  align-items: center;
}
.ant-space-align-start {
  align-items: flex-start;
}
.ant-space-align-end {
  align-items: flex-end;
}
.ant-space-align-baseline {
  align-items: baseline;
}
.ant-space-item:empty {
  display: none;
}
.ant-space-compact {
  display: inline-flex;
}
.ant-space-compact-block {
  display: flex;
  width: 100%;
}
.ant-space-compact-vertical {
  flex-direction: column;
}
.ant-space-rtl {
  direction: rtl;
}
.ant-space-compact-rtl {
  direction: rtl;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
}
.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: auto;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.ant-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 2px 0 0 2px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-rtl {
  direction: rtl;
}

